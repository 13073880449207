window.Util = (($) ->

  # Toggle links
  toggleLinks = ->
    $(document).on 'click', '[data-toggle]', (e) ->
        $this = $(e.currentTarget)
        target = $this.attr('data-toggle-target') || 'body'
        $target = $(target)
        cls = $this.attr('data-toggle')

        if($this.is('[data-toggle-remove]'))
          $target.removeClass(cls)
        else if($this.is('[data-toggle-add]'))
          $target.addClass(cls)
        else
          $target.toggleClass(cls)

  # Turn the scrollTo links to smooth-scrolling links
  scrollToLinks = ($el, speed = 500, offset = 115) ->
    $el.on 'click', (e) ->
      e.preventDefault()
      href = $(this).attr 'href'
      targetTop = $(href).offset().top - offset

      # Animate the scroll
      $('body, html').animate
        scrollTop: targetTop
      , speed
      #, -> window.location.hash = href

  # Offset the anchor's top, to account for things like fixed elements
  offsetAnchorTop = (offset, smoothScroll = true, smoothScrollSpeed = 500) ->
    # Test if a hash is proper, that is, not a #! or #/ pseudo-hash
    isRealHash = (hash) -> hash && hash.match '^#[a-zA-Z0-9]'

    # The scrolling function
    scrollToRealTop = (hash = window.location.hash, speed = 1) ->
      if isRealHash(hash) # Don't match #! and #/ pseudo-hashes
        $el = $ hash
        $('body, html').animate
          scrollTop: $el.offset().top - offset
        , speed

    # Scroll on initial load, and on hashchange
    scrollToRealTop()
    $(window).on 'hashchange load', ->
      setTimeout ->
        scrollToRealTop()
      , 5

    # Smooth scroll on links to anchors
    if smoothScroll
      $('a[href*="#"]').on 'click touchend', (e) ->
        currentPage = window.location.href.replace(/\/+#.*/, '').toLowerCase()
        hrefParts = $(this).attr('href').match(/(.*)#(.*)/)
        page = encodeURI(hrefParts[1]).toLowerCase()
        hash = '#' + hrefParts[2]

        if isRealHash(hash) and page == currentPage
          e.preventDefault()
          scrollToRealTop hash, 500

  # Scroll up button
  scrollUp = (sel = '.scroll-up', speed = 500) ->
    $el = $ sel
    $el.on 'click touchend', (e) ->
      e.preventDefault()
      $('html, body').animate
        scrollTop: 0
      , speed


  # Mark focused parent items of links, for accesibility
  markFocused = (sel) ->
    $el = $ sel
    $el.find('a, [tabindex]')
        .on 'focus', ->
          $(this).parents(sel).addClass 'is-focused'
        .on 'blur', ->
          $(this).parents(sel).removeClass 'is-focused'

  # Get the current section
  getCurrentSection = (sel) ->
    centerElement = document.elementFromPoint $(window).width() / 2, $(window).height() / 2
    $centerElement = $ centerElement

    if $centerElement.is sel
      $centerElement
    else
      parent = $centerElement.parents().filter sel
      if parent.length then parent else null

  # Mark current section
  markCurrentSection = (sel) ->
    $el = $ sel
    $a = $ 'a'

    mark = ->
      $section = $ getCurrentSection(sel)
      $el.removeClass 'is-current-section'
      $section.addClass 'is-current-section'

      # Mark the links that point to it
      $a.removeClass 'is-current-section-link'
      $a.filter('[href=#' + $section.attr('id') + ']').addClass 'is-current-section-link'

    setInterval mark, 500

  # In Field labels
  inFieldLabels = (selector, fadeTime) ->
    fadeTime = fadeTime || 100
    inputsSelectors = []

    # Create a selector for the various textual input types
    $.each ['.dk_container', 'input[type="text"]', 'input[type="email"]',
            'input[type="tel"]', 'input[type="password"]', 'textarea'], () ->
      inputsSelectors.push(selector + ' ' + this)

    inputsSelectors = inputsSelectors.join(',')
    inputs = $(inputsSelectors)

    # If the input has text in it, hide the label
    inputs.each () ->
      if $(this).val()
        $(this).parents(selector).addClass('is-has-label')

    # If the label is clicked, hide the label
    $(selector + ' label:not(.error)').click () -> $(this).parents(selector).addClass('is-has-label')

    # Hide or show the label according to focus
    $(document).on 'input', inputsSelectors, () ->
      console.log $(this).parents(selector)
      if $(this).val()
        $(this).parents(selector).addClass('is-has-label')
      else
        $(this).parents(selector).removeClass('is-has-label')

    $(document).on 'focus', inputsSelectors, () -> $(this).parents(selector).addClass('is-has-label')
    $(document).on 'blur', inputsSelectors, () -> if !$(this).val() then $(this).parents(selector).removeClass('is-has-label')

  # Create a full-screen section
  fullScreenSection = (sel, offset) ->
    $el = $ sel
    resize = () -> $el.css('min-height', $(window).height() - offset)
    resize()
    $(window).on 'resize load', -> resize()

  markLoadedImages = () ->
    $('img').one('load', () ->
      $(this).addClass('is-loaded')
    ).each(() ->
      if this.complete then $(this).load()
    );

  exports =
    scrollToLinks: scrollToLinks
    markFocused: markFocused
    getCurrentSection: getCurrentSection
    markCurrentSection: markCurrentSection
    fullScreenSection: fullScreenSection
    offsetAnchorTop: offsetAnchorTop
    inFieldLabels: inFieldLabels
    scrollUp: scrollUp
    markLoadedImages: markLoadedImages
    toggleLinks: toggleLinks

)(jQuery)